<template>
  <div class="partner">
    <div class="container">
      <div class="about-title">
        <h1 class="primary-title">联系我们</h1>
        <h2 class="secondary-title">CONTACT US</h2>
      </div>
      <div class="about-content">
        <div class="about-item-content">
          <div class="about-item-title">市场服务</div>
          <div class="about-item-body">
            <p>联系人：邱先生</p>
            <p>电话/微信：15370033777</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">商务服务</div>
          <div class="about-item-body">
            <p>联系人：刘小姐</p>
            <p>电话/微信：15021148676</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">客户服务</div>
          <div class="about-item-body">
            <p>热线电话：400-082-2298</p>
            <p>电话/微信：19512368757</p>
            <p>地址：上海普陀区中江路118号</p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'contact',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇红包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner > .img {
  width: 100%;
  height: 50%;
  background-color: #f9f9f9;
  vertical-align: middle;
  border: 0;
}

.about-title {
  top: 15%;
  padding: 20px 50px;
  width: 100%;
  color: #000;
  position: relative;
}

.primary-title {
  font-size: 40px;
}

.secondary-title {
  margin-top: 10px;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Times New Roman", serif;
}

.container {
  position: relative;
}

.about-content {
  margin: 0px auto 0;
  padding: 30px 50px;
  width: 100%;
  max-width: 1090px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 20px 15px -5px #d3cfc5;
}

.about-item-content {
  margin-top: 10px;
}

.about-item-title {
  color: #ff4141;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.about-item-body {
  margin-bottom: 30px;
  line-height: 1.5;
  text-indent: 2em;
}

p {
  margin: 0 0 10px;
}
</style>
